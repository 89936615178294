import React, {useState} from 'react';
import * as styles from './Form.module.scss';
import Input from "../../atoms/Input";
import StyledButton from '../../atoms/StyledButton';
import { IoIosArrowForward } from 'react-icons/io';
import axios from "axios";

const Form = () => {
    const [email, setEmail] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [subject, setSubject] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [success, setSuccess] = useState(false);
    const [preferred, setPreferred] = useState("");

    const [emailError, setEmailError] = useState("");

    const validateEmail = (e) => {
        setEmail(e);
    };

    const validateName = (e) => {
        setFirstName(e);
    };

    const validateSubject = (e) => {
        setSubject(e);
    }

    const validateLastName = (e) => {
        setLastName(e);
    }

    const validateCompanyName = (e) => {
        setCompanyName(e);
    }

    const validatePhoneNumber = (e) => {
        setPhoneNumber(e);
    }

    const preferredMethod = (e) => {
        console.log(e);
        setPreferred(e);
    }

    const clearFields = () => {
        setEmail('');
        setPhoneNumber('');
        setCompanyName('');
        setLastName('');
        setFirstName('');
        setSubject('');
    }

    const handleSubmit = async () => {
        await axios({
            method: "post",
            url: "https://api.sendinblue.com/v3/smtp/email",
            data: {
                "sender":{
                    // "name": firstName + ' ' + lastName,
                    "email": email
                },
                "to":[
                    {
                        "email":"support@masterwizr.com",
                        "name":"MASTERWiZR WEBSITE"
                    }
                ],
                "subject":"Master WiZR contact form",
                "htmlContent":"<html>" +
                    "<body><p>Hello,</p>" +
                    "<p>" + subject + "</p>" +
                    "<p>Phone Number: " + phoneNumber + "</p>" +
                    "<p>Company Name: " + companyName + "</p>" +
                    "<p>Preferred Method: " + preferred + "</p>" +
                    "<hr/>" +
                    "<p>This message has been sent from Master WiZR contact form.</p>" +
                    "</body>" +
                    "</html>"
            },
            headers: {
                "Content-Type": "application/json",
                "api-key":
                    "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",
            },
        })
            .then(function (response) {
                clearFields();
                setSuccess(true);
            })
            .catch(function (response) {
                setEmailError("Something went wrong!");
                console.log(response);
            });
    };
    return (
        <div className={styles.container}>
            <h2>Write us today</h2>
            {/*<Input value={firstName} type='text' label='First Name' placeholder ='Enter your first name' required={true} onChange={(e) => validateName(e)}/>*/}
            {/*<Input value={lastName} type='text' label='Last Name' placeholder='Enter your last name' required={true} onChange={(e) => validateLastName(e)}/>*/}
            <Input value={companyName} type='text' label='Company Name' placeholder='Enter your company name' onChange={validateCompanyName}/>
            <Input value={email} type='email' label='Email Address' placeholder='Enter your email address' required={true}  onChange={validateEmail}/>
            <Input value={phoneNumber} type='number' label='Phone Number' placeholder='Enter your phone no.' onChange={validatePhoneNumber}/>
            <span className={styles.contact} style={{alignSelf: "flex-start" , color: "white" ,fontSize: "1.6rem" , fontWeight: "bold" , lineHeight: "1.9rem", marginLeft: "72px"}}>Preferred Contact Method</span>
            <select label="Preferred" onChange={(e) => preferredMethod(e.target.value)}>
                <option value="phone">Phone</option>
                <option selected value="email">Email</option>
            </select>
            <Input value={subject} type='textarea' label='Message' placeholder='Write your message' onChange={validateSubject}/>
            <StyledButton email={true} onClick={() => handleSubmit()} title="Submit" icon={<IoIosArrowForward />} extraClass="orangeGray"/>
            {
                success && (
                    <p className={styles.success}>Message successfully sent.</p>
                )
            }
        </div>
    );
};

export default Form;
