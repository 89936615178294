import React, { useEffect } from "react";
import Footer from "../components/atoms/footer";
import Header from "../components/atoms/header";
import ContactForm from "../components/ogranisms/ContactForm";
import FreeTrial from "../components/atoms/freeTrial";
import Head from "../components/ogranisms/head";
import AOS from "aos";
import "aos/dist/aos.css";
function Modules() {
  useEffect(() => {
    AOS.init({
      disable: "mobile",
      duration: 1500,
    });
  }, []);
  return (
    <>
      <Head
        title="Welcome to the WiZR world"
        description="Welcome to the WiZR world
          The place to organize, present, share and collaborate. Cultivating what matters the most to you."
        ogTitle="About us"
      />
      <main>
        <Header />
        <ContactForm />
        <FreeTrial />
        <Footer />
      </main>
    </>
  );
}

export default Modules;
